<template lang="pug">
    section#mosaico
        ul.mosaico
            li(v-for="image, index in getImage", :class="`_0${index+1}`")
                img(:src="image.image")
        .content
            Editor(:contents="editorTop")
            .buttons
                a(:href="contents.content.leftButtonLink").squareButton {{contents.content.leftButtonText}}
                a(:href="filteredSocials").squareButton
                    SvgIcon(data="@svgs/linkedin.svg", original)
                    |{{contents.content.rightButtonText}}
            Editor(:contents="editorBottom")
</template>

<script>
import { props } from '@/mixins/component'
import Editor from '@sections/Pagina/Editor/Editor'

export default {
    name: "section-career-section",
    props,
    components: {
        Editor,
    },
    computed: {
        getImage() {
            return this.contents.content.mosaico.map(item => ({
                ...item,
                image: `${process.env.VUE_APP_FILE_API_ROUTE}/medias/media/${item}`
            }))
        },
        editorTop() {
            return {content: this.contents.content.editorTop}
        },
        editorBottom() {
            return {content: this.contents.content.editorBottom}
        },
        social() {
            return this.$store.state.settings
        },
        filteredSocials() {
            return this.social.find(rede => rede.attribute == 'linkedin')?.value
        }
    },
    created() {
        this.$store.dispatch('fetchSettings')
    },
}
</script>

<style lang="stylus" scoped src="./CareerSection.styl"></style>